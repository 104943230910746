import { camelCase } from "lodash";
import fetchJson from "../utils/fetchJson";
import getUrl from "../utils/getUrl";

const parseStorage = () => {
  try {
    return JSON.parse(window.sessionStorage.getItem("neru"));
  } catch (err) {
    return {};
  }
};
const getStorageItem = (key: string) => {
  const existingStorage = parseStorage();

  return existingStorage[key];
};

const removeStorageItem = (key: string) => {
  const existingStorage = parseStorage();

  const { [key]: toBeRemoved, ...newStorage } = existingStorage;

  window.sessionStorage.setItem("neru", JSON.stringify(newStorage));
};

const setStorageItem = (key: string, value: any) => {
  const existingStorage = parseStorage();

  window.sessionStorage.setItem(
    "neru",
    JSON.stringify({
      ...existingStorage,
      [key]: value,
    })
  );
};

const swapVariant = async () => {
  const controlElements = document.querySelectorAll(`[data-neru]`);

  // Display Variant
  const controlProps = [...controlElements].reduce((acc, el) => {
    const type = el.dataset.type;

    return {
      ...acc,
      [camelCase(type)]: el.textContent,
    };
  }, {});

  const button = document.querySelector(`[data-neru][data-action]`);
  const intent = camelCase(button.dataset.intent);
  const language = camelCase(button.dataset.language);

  const variants = await fetchJson({
    url: getUrl({ slug: "/api/get-variants" }),
    method: "POST",
    data: {
      controlProps,
      intent,
      language,
      url: window.location.href,
    },
  });

  const getRandomVariant = (obj: Record<string, any>) => {
    var keys = Object.keys(obj);

    const variant = keys[(keys.length * Math.random()) << 0];

    return { ...obj[variant], variantId: variant };
  };

  const variant = getRandomVariant(variants);

  setStorageItem("variant", variant);

  controlElements.forEach((el) => {
    const type = el.dataset.type;

    if (!type) return;

    el.textContent = variant[camelCase(type)];
  });

  return true;
};

const trackPageView = async () => {
  // Don't send analytics if it's a bot
  if (
    window.phantom ||
    // eslint-disable-next-line no-underscore-dangle
    window._phantom ||
    // eslint-disable-next-line no-underscore-dangle
    window.__nightmare ||
    window.navigator.webdriver ||
    window.Cypress
  )
    return;

  const variant = getStorageItem("variant");

  await fetchJson({
    url: getUrl({ slug: "/api/track-page-view" }),
    method: "POST",
    data: {
      variantId: variant.variantId,
      url: window.location.href,
    },
    keepalive: true,
  });
};

let isConverted = false;

const trackPageConversion = async () => {
  if (isConverted) return false;

  isConverted = true;

  const variant = getStorageItem("variant");

  await fetchJson({
    url: getUrl({ slug: "/api/track-page-conversion" }),
    method: "POST",
    data: {
      variantId: variant.variantId,
      url: window.location.href,
    },
    keepalive: true,
  });
};

const loadScript = async () => {
  const isSwapped = await swapVariant();

  if (!isSwapped) return;

  await trackPageView();

  const button = document.querySelector(`[data-neru][data-action]`);

  if (button) {
    button.addEventListener("click", trackPageConversion);
  }
};

loadScript();

window.addEventListener("DOMContentLoaded", loadScript);
