const fetchJson = async ({
  url,
  method = "GET",
  data = {},
  headers = {},
  keepalive = false,
}: {
  url: string;
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  data?: Record<string, any>;
  headers?: Record<string, any>;
  keepalive?: Boolean;
}) => {
  const result = await fetch(url, {
    method,
    headers: { "Content-Type": "application/json", ...headers },
    ...((method === "POST" ||
      method === "PUT" ||
      method === "PATCH" ||
      method === "DELETE") && {
      body: JSON.stringify(data),
    }),
    keepalive,
  });

  const resultData = await result.json();

  if (!result.ok) {
    const error = new Error(resultData?.error?.message || resultData.message);
    error.status = result.status;
    error.data = resultData.data || {};

    throw error;
  }

  return resultData;
};

export default fetchJson;
